<template>
  <main-layout>
    <div class="d-flex align-items-center">
      <base-button @click="$router.back()" :back="true">
        <horizontal-arrow />
      </base-button>
      <h1 class="m-0 ms-3">{{ $t("addExpertInformation") }}</h1>
    </div>
    <div class="expertAddPage__steps-wrapper">
      <div
        class="expertAddPage__steps"
        :class="{ 'expertAddPage__steps-active': steps[0] }"
      >
        1. {{ $t("Information") }}
      </div>
      <div
        class="expertAddPage__steps"
        :class="{ 'expertAddPage__steps-active': steps[1] }"
      >
        2. {{ $t("Biography") }}
      </div>
      <div
        class="expertAddPage__steps"
        :class="{ 'expertAddPage__steps-active': steps[2] }"
      >
        3. {{ $t("sidebarServicesBtn") }}
      </div>
      <div
        class="expertAddPage__steps"
        :class="{ 'expertAddPage__steps-active': steps[3] }"
      >
        4. {{ $t("Avatar") }}
      </div>
    </div>
    <div class="accountCreate__credentialsWrapper ms-0">
      <div class="d-flex align-items-center h-100">
        <div class="d-flex">
          <div>
            <div class="accountCreate__title">
              {{ $t("accountOverviewPageEmail") }}
            </div>
            <div
              v-if="!!credentials && !!credentials.email"
              class="accountCreate__emailContent"
            >
              {{ credentials.email }}
            </div>
          </div>
          <div class="accountCreate__password">
            <div class="accountCreate__title">{{ $t("password") }}</div>
            <div v-if="!!credentials">
              <div
                v-if="credentials.isNewUser"
                class="d-flex align-items-center"
              >
                {{ passwordMaskState ? passwordMask : credentials.password }}
                <div
                  class="ml-15 cursor-pointer"
                  @click="passwordMaskState = !passwordMaskState"
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g opacity="0.5">
                      <path
                        d="M8.09629 5.24407C9.02728 4.86984 10.0477 4.77743 11.0308 4.97834C12.0138 5.17925 12.9162 5.66462 13.6257 6.37412C14.3352 7.08362 14.8206 7.98597 15.0215 8.96904C15.2224 9.9521 15.13 10.9725 14.7557 11.9035L16.3918 13.5396C17.8335 12.3235 18.7502 10.9774 18.7502 10.1996C18.7502 8.88629 16.4091 6.12129 13.6741 4.76629C12.5359 4.1837 11.2787 3.87135 10.0002 3.85352C9.05258 3.86178 8.11402 4.0388 7.22852 4.37629L8.09629 5.24407Z"
                        fill="black"
                      />
                      <path
                        d="M18.7735 17.8092L12.4035 11.4392C12.668 11.0053 12.809 10.5073 12.8113 9.99916C12.8079 9.77371 12.7754 9.54963 12.7146 9.3325C12.5868 9.54641 12.4062 9.72399 12.1902 9.84828C11.9742 9.97258 11.7299 10.0394 11.4807 10.0425C11.0883 10.0425 10.712 9.88685 10.4342 9.60971C10.1564 9.33256 9.99995 8.95655 9.99907 8.56416C10.0019 8.30651 10.0727 8.05419 10.2044 7.83273C10.3361 7.61127 10.524 7.42854 10.7491 7.30305C10.5056 7.22984 10.2532 7.19076 9.99907 7.18694C9.49089 7.18919 8.99297 7.33019 8.55907 7.59472L2.18907 1.22472C2.10377 1.1396 1.98818 1.0918 1.86768 1.0918C1.74717 1.0918 1.63159 1.1396 1.54629 1.22472L1.22518 1.54639C1.1829 1.58855 1.14935 1.63864 1.12647 1.69379C1.10358 1.74894 1.0918 1.80806 1.0918 1.86777C1.0918 1.92749 1.10358 1.98661 1.12647 2.04176C1.14935 2.09691 1.1829 2.147 1.22518 2.18916L4.75074 5.71472C2.63074 7.23694 1.25073 9.28028 1.25073 10.1992C1.25073 11.8658 5.39407 16.1436 9.96518 16.1436C11.4099 16.1148 12.8271 15.7439 14.1007 15.0614L17.8113 18.7719C17.8535 18.8142 17.9035 18.8478 17.9587 18.8707C18.0138 18.8935 18.073 18.9053 18.1327 18.9053C18.1924 18.9053 18.2515 18.8935 18.3067 18.8707C18.3618 18.8478 18.4119 18.8142 18.4541 18.7719L18.7752 18.4508C18.8599 18.3654 18.9073 18.2499 18.9069 18.1297C18.9066 18.0094 18.8586 17.8941 18.7735 17.8092ZM9.99907 15.1242C9.04306 15.1273 8.10533 14.8622 7.29242 14.3591C6.47951 13.856 5.82401 13.1349 5.40038 12.2779C4.97675 11.4209 4.80198 10.4622 4.89593 9.51081C4.98988 8.55942 5.34877 7.65345 5.93185 6.89583L7.59518 8.55916C7.33008 8.99285 7.18886 9.49088 7.18685 9.99916C7.18677 10.3685 7.25946 10.7343 7.40075 11.0755C7.54205 11.4168 7.74918 11.7269 8.01033 11.9881C8.27148 12.2493 8.58152 12.4565 8.92275 12.5978C9.26398 12.7392 9.62971 12.8119 9.99907 12.8119C10.5074 12.8097 11.0054 12.6683 11.4391 12.4031L13.1024 14.0664C12.2133 14.7523 11.122 15.1243 9.99907 15.1242Z"
                        fill="black"
                      />
                    </g>
                  </svg>
                </div>
              </div>
              <div v-else class="d-flex align-items-center">
                <div class="mr-10">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="20" height="20" fill="white" />
                    <path
                      d="M14.2956 8.33626H14.1105L14.1106 5.57955C14.1106 3.05419 12.056 1 9.5307 1C7.00538 1 4.95081 3.05429 4.95081 5.57955V8.33626H4.76569C3.79103 8.33626 3 9.12661 3 10.102V17.2343C3 18.2097 3.79076 19 4.76569 19H14.2954C15.2701 19 16.0611 18.2098 16.0611 17.2343L16.0612 10.102C16.0611 9.12675 15.2702 8.33626 14.2955 8.33626H14.2956ZM6.68039 5.57955C6.68039 4.00795 7.95895 2.72938 9.53069 2.72938C11.1024 2.72938 12.381 4.00795 12.381 5.57955V8.33626H6.68032L6.68039 5.57955ZM10.2675 14.4713V16.3256C10.2675 16.7324 9.9376 17.0623 9.53075 17.0623C9.12391 17.0623 8.79401 16.7325 8.79401 16.3256V14.4713C8.20648 14.1944 7.79938 13.5986 7.79938 12.906C7.79938 11.9496 8.57445 11.1744 9.53089 11.1744C10.4873 11.1744 11.2624 11.9496 11.2624 12.906C11.2623 13.5987 10.855 14.1941 10.2675 14.4713Z"
                      fill="black"
                    />
                  </svg>
                </div>
                {{ passwordMask }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="accountCreate__edit" @click="showCredentialsPopup()">
        {{ $t("Edit") }}
      </div>
    </div>
    <div class="expertAddPage">
      <div v-if="steps[0]" class="row">
        <div class="col-12 col-lg-6">
          <div class="expertAddPage__input">
            <base-input
              class="w-100"
              v-model="information.name"
              type="text"
              :error="v$.information.name.$error"
              :error-message="v$.information.name.$errors"
            >
              {{ $t("accountOverviewPageName") }}*
            </base-input>
          </div>
          <div class="expertAddPage__input">
            <base-input
              class="w-100"
              v-model="information.surname"
              type="text"
              :error="v$.information.surname.$error"
              :error-message="v$.information.surname.$errors"
            >
              {{ $t("accountOverviewPageSurname") }}*
            </base-input>
          </div>
          <div class="expertAddPage__input">
            <base-input
              class="w-100"
              v-model="information.code"
              :maxlength="15"
              type="text"
              :error="v$.information.code.$error"
              :error-message="v$.information.code.$errors"
            >
              {{ $t("expertReference") }}*
            </base-input>
          </div>
          <div class="expertAddPage__input">
            <base-input class="w-100" v-model="information.country" type="text">
              {{ $t("country") }}
            </base-input>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <base-input
                class="w-100 expertAddPage__input"
                v-model="information.city"
              >
                {{ $t("city") }}
              </base-input>
            </div>
            <div class="col-lg-6">
              <base-input
                class="w-100 expertAddPage__input"
                v-model="information.postal_code"
              >
                {{ $t("postalCode") }}
              </base-input>
            </div>
          </div>
          <div class="expertAddPage__input">
            <base-input class="w-100" v-model="information.address" type="text">
              {{ $t("accountOverviewPageAddress") }}
            </base-input>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="d-flex justify-content-between">
            <div class="expertAddPage__input accountPage__input-small">
              <span
                :class="{
                  'baseInput__area-focus expertAddPage__input-label':
                    information.gender,
                }"
                class="baseInput__label"
              >
                {{ $t("accountOverviewPageGender") }}
              </span>
              <vue-multiselect
                class="accountOverviewMultiselect"
                :options="genderOptions"
                v-model="information.gender"
                placeholder=""
                :show-labels="false"
                openDirection="bottom"
                label="name"
                track-by="name"
              />
            </div>
            <div class="position-relative accountPage__input-small mb-0">
              <span
                class="baseInput__label"
                :class="{ 'baseInput__area-focus': information.birth }"
              >
                {{ $t("accountOverviewPageBirth") }}</span
              >
              <datepicker
                inputClassName="registrationPage__datePicker"
                class=""
                v-model="information.birth"
                format="dd/MM/yyyy"
                :flow="flow"
                hideInputIcon
                autoApply
              />
            </div>
          </div>
          <div class="expertAddPage__input">
            <span
              :class="{
                'baseInput__area-focus expertAddPage__input-label':
                  information.platformLanguage,
              }"
              class="baseInput__label"
            >
              {{ $t("platformLanguage") }}*
            </span>
            <vue-multiselect
              class="accountOverviewMultiselect"
              :options="platformLanguageOptions"
              v-model="information.platformLanguage"
              placeholder=""
              :show-labels="false"
              openDirection="bottom"
              label="name"
              track-by="name"
            />
          </div>
          <div class="expertAddPage__input">
            <span
              :class="{
                'baseInput__area-focus expertAddPage__input-label':
                  information.languages && information.languages.length > 0,
              }"
              class="baseInput__label"
            >
              {{ $t("accountOverviewPageLanguage") }}*
            </span>
            <vue-multiselect
              class="accountOverviewMultiselect"
              :class="{ baseInput__error: v$.information.languages.$error }"
              :multiple="true"
              :taggable="true"
              :options="languageOptions"
              v-model="information.languages"
              placeholder=""
              :show-labels="false"
              openDirection="bottom"
              label="name"
              track-by="name"
            />
            <small v-if="v$.information.languages.$error">{{
              $t("fieldRequireError")
            }}</small>
          </div>
          <div class="expertAddPage__input">
            <base-input
              class="w-100"
              v-model="information.office_number"
              type="phone"
            >
              {{ $t("accountOverviewPageOffice") }}
            </base-input>
          </div>
          <div class="expertAddPage__input">
            <base-input
              class="w-100"
              v-model="information.mobile_number"
              type="phone"
            >
              {{ $t("accountOverviewPageMobile") }}
            </base-input>
          </div>
          <div class="expertAddPage__input">
            <base-input
              class="w-100"
              v-model="information.phone_number"
              type="phone"
            >
              {{ $t("accountOverviewPagePhone") }}
            </base-input>
          </div>
        </div>
      </div>
      <div v-if="steps[1]">
        <div class="d-flex justify-content-center">
          <div
            class="expertAddPage__language"
            :class="{ 'expertAddPage__language-active': !language.english }"
            @click="languageChange('english')"
          >
            {{ $t("French") }}
          </div>
          <div
            class="expertAddPage__language"
            :class="{ 'expertAddPage__language-active': !language.french }"
            @click="languageChange('french')"
          >
            {{ $t("English") }}
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <base-input
            v-show="language.english"
            v-model="information.position.english"
            class="servicesAddPage__input"
          >
            {{ $t("accountOverviewPagePosition") }}
          </base-input>
          <base-input
            v-show="language.french"
            v-model="information.position.french"
            class="servicesAddPage__input"
          >
            {{ $t("accountOverviewPagePosition") }}
          </base-input>
        </div>
        <div class="expertAddPage__titleContainer">
          <div class="expertAddPage__title">
            {{ $t("shortDescription") }}
          </div>
        </div>
        <div class="expertAddPage__shortDescription">
          <div v-show="language.english">
            <ckeditor
              :editor="editor"
              :config="editorConfig"
              v-model="information.shortBiography.english"
            ></ckeditor>
          </div>
          <div v-show="language.french">
            <ckeditor
              :editor="editor"
              :config="editorConfig"
              v-model="information.shortBiography.french"
            ></ckeditor>
          </div>
        </div>
        <div class="expertAddPage__titleContainer">
          <div class="expertAddPage__title">
            {{ $t("fullDescription") }}
          </div>
        </div>
        <div class="expertAddPage__fullDescription">
          <div v-show="language.english">
            <ckeditor
              :editor="editor"
              :config="editorConfig"
              v-model="information.fullBiography.english"
            ></ckeditor>
          </div>
          <div v-show="language.french">
            <ckeditor
              :editor="editor"
              :config="editorConfig"
              v-model="information.fullBiography.french"
            ></ckeditor>
          </div>
        </div>
      </div>
      <div v-if="steps[2]">
        <add-service-on-expert
          v-for="service in services"
          :key="service.id"
          :index="service.id"
          :service-information="service"
          :remove-service="removeService"
          v-model="servicesValue[service.id]"
        />
        <div class="expertAddPage__addServices" @click="addService()">
          {{ $t("addServiceOnExpert") }}
        </div>
      </div>
      <div v-show="steps[3]" class="expertAddPage__stepThree">
        <avatar
          v-if="oldImage"
          class="expertAddPage__oldImage"
          user-role="experts"
          size="500"
          :drag-and-drop-image="true"
          :service_id="expert_code"
          :image-name="oldImage"
          height="100%"
        />
        <drag-and-drop
          ref="thumbnail"
          :avatar="true"
          v-model="thumbnail"
          :have-old-image="oldImage"
        />
      </div>
      <div
        class="expertAddPage__footer"
        :class="{
          'justify-content-between': steps[3] && (oldImage || thumbnail),
        }"
      >
        <base-button
          v-if="steps[3] && (oldImage || thumbnail)"
          class="popup__remove-logo-btn"
          @click="removeImage()"
        >
          {{ $t("RemoveAvatar") }}
        </base-button>
        <div class="expertAddPage__buttons">
          <div
            v-if="step !== 0"
            @click="backStep()"
            class="accountPage__cancel"
          >
            {{ $t("back") }}
          </div>
          <base-button
            :is-loaded="imageIsLoaded"
            :book="true"
            @click="nextStep()"
          >
            {{ step === 3 ? $t("submit") : $t("nextStep") }}
          </base-button>
        </div>
      </div>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "@/layouts/MainLayout";
import BaseInput from "@/components/UI/inputs/BaseInput";
import VueMultiselect from "vue-multiselect";
import BaseButton from "@/components/UI/buttons/BaseButton";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DragAndDrop from "@/components/elements/DragAndDrop";
import AddServiceOnExpert from "@/components/elements/AddServiceOnExpert";
import Avatar from "@/components/UI/images/Avatar";
import HorizontalArrow from "@/components/UI/icons/arrows/HorizontalArrow";
import useVuelidate from "@vuelidate/core";
import { minLength, required } from "@vuelidate/validators";
import { mapGetters } from "vuex";
import { useToast } from "vue-toastification";
import { ref } from "vue";
import Datepicker from "@vuepic/vue-datepicker";

export default {
  setup() {
    const toast = useToast();
    const v$ = useVuelidate();
    const flow = ref(["year", "month", "calendar"]);

    return {
      flow,
      toast,
      v$,
    };
  },
  name: "ExpertAddPage",
  components: {
    HorizontalArrow,
    Avatar,
    AddServiceOnExpert,
    DragAndDrop,
    BaseButton,
    BaseInput,
    MainLayout,
    VueMultiselect,
    Datepicker,
  },
  data() {
    return {
      passwordMaskState: true,
      passwordMask: "************",
      haveChanges: true,
      services: [],
      servicesValue: [],
      thumbnail: null,
      expert_code: 0,
      oldImage: null,
      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "italic",
            "link",
            "bulletedList",
            "numberedList",
            "|",
            "outdent",
            "indent",
            "|",
            "blockQuote",
            "undo",
            "redo",
          ],
        },
      },
      steps: [true, false, false, false],
      step: 0,
      genderOptions: [],
      languageOptions: [],
      platformLanguageOptions: [],
      language: {
        english: false,
        french: true,
      },
      information: {
        name: null,
        surname: null,
        password: null,
        country: null,
        city: null,
        postal_code: null,
        address: null,
        mobile_number: null,
        gender: null,
        birth: null,
        email: null,
        languages: null,
        platformLanguage: null,
        profile: {
          language_id: null,
        },
        position: {
          english: null,
          french: null,
        },
        code: null,
        office_number: null,
        phone_number: null,
        shortBiography: {
          english: null,
          french: null,
        },
        fullBiography: {
          english: null,
          french: null,
        },
      },
      backupValue: {},
    };
  },
  validations() {
    return {
      information: {
        name: { required },
        surname: { required },
        code: { required, minLength: minLength(13) },
        languages: { required },
      },
    };
  },
  beforeRouteLeave(to, from, next) {
    if (!this.haveChanges) {
      let changesPopup = {
        show: true,
        to: to.path,
        save: null,
      };
      this.$store.commit("SET_STATE_POPUP", changesPopup);
    } else {
      return next();
    }
  },
  mounted() {
    this.getOptions();
    this.$store.commit("SET_LOADED_STATE", false);
  },
  methods: {
    removeImage() {
      this.oldImage = null;
      this.thumbnail = null;
      this.$refs.thumbnail.remove();
    },
    getOptions() {
      this.$http.get("genders", {}).then(({ data }) => {
        this.genderOptions = data.body;
      });
      this.$http.get("languages", {}).then(({ data }) => {
        this.languageOptions = data.body;
      });
      this.$http.get("platform-languages", {}).then(({ data }) => {
        this.platformLanguageOptions = data.body;
        this.information.platformLanguage = data.body[1];
        this.backupValue = { ...this.information };
      });
    },
    languageChange(language) {
      for (let i in this.language) {
        this.language[i] = i !== language;
      }
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.languageOptions.push(tag);
      this.information.language.push(tag);
    },
    nextStep() {
      this.v$.$touch();
      if (this.v$.$error) {
        this.toast.error(this.$t("checkInputs"));
        return;
      }
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      if (this.step < 3) {
        this.step++;
        for (let i in this.steps) {
          this.steps[i] = false;
        }
        this.steps[this.step] = true;
      } else {
        this.createExpert();
      }
    },
    backStep() {
      if (this.step > 0) {
        this.step--;
        for (let i in this.steps) {
          this.steps[i] = false;
        }
        this.steps[this.step] = true;
      } else {
        this.$router.back();
      }
    },
    addService() {
      this.services.push({ id: null, type: "", consultations: [] });
    },
    removeService(value) {
      this.services = this.services.filter((element) => element.id !== value);
    },
    createExpert() {
      this.v$.$touch();
      if (this.v$.$error) {
        this.haveChanges = false;
        this.toast.error(this.$t("checkInputs"));
        return;
      }
      let languages = [];
      let services = [];
      if (this.information.languages) {
        const languagesList = [...this.information.languages];
        languagesList.forEach((item) => {
          languages.push(item.id);
        });
      }
      if (this.servicesValue) {
        this.servicesValue.forEach((service) => {
          service.consultations.forEach((item) => {
            services.push({
              service_id: Number(service.id),
              translations: item.translations,
              price: Number(item.price),
              expert_income: Number(item.expert_income),
              duration: Number(item.duration),
            });
          });
        });
      }
      this.$store.commit("SET_LOADED_STATE", true);
      this.$http
        .post("experts", {
          gender_id: this.information.gender
            ? this.information.gender.id
            : null,
          code: this.information.code,
          first_name: this.information.name,
          last_name: this.information.surname,
          birthdate: new Date(this.information.birth).toISOString(),
          country: this.information.country,
          address: this.information.address,
          city: this.information.city,
          postal_code: this.information.postal_code,
          mobile_number: this.information.mobile_number,
          phone_number: this.information.phone_number,
          office_number: this.information.office_number,
          image: this.thumbnail,
          is_activated: true,
          languages: languages,
          profile: {
            language_id: this.information.platformLanguage.id,
          },
          user: {
            email: this.credentials.email,
            password: this.credentials.password,
          },
          consultations: services,
          translations: [
            {
              language_id: 1,
              position: this.information.position.english,
              short_biography: this.information.shortBiography.english,
              biography: this.information.fullBiography.english,
            },
            {
              language_id: 2,
              position: this.information.position.french,
              short_biography: this.information.shortBiography.french,
              biography: this.information.fullBiography.french,
            },
          ],
        })
        .then(() => {
          this.toast.success(this.$t("successfulCreateExpert"));
          this.$store.commit("SET_LOADED_STATE", false);
          this.$store.commit("SET_NEW_USER_CREDENTIALS", null);
          this.haveChanges = true;
          this.$router.push("/experts");
        })
        .catch((err) => {
          if (err.response.data.status === 400) {
            this.haveChanges = false;
            this.$store.commit("SET_LOADED_STATE", false);
            this.toast.error(err.response.data.message);
          } else {
            this.$store.commit("SET_LOADED_STATE", false);
            this.toast.error(this.$t("NetworkError"));
          }
        });
    },
    passwordGenerate() {
      this.information.password = Math.floor(
        Math.random() * 1000000000000000000
      ).toString(36);
    },
    showCredentialsPopup() {
      let popupState = this.$store.getters.CREDENTIALS_POPUP;
      this.$store.commit("SET_CREDENTIALS_POPUP", !popupState);
    },
  },
  computed: {
    ...mapGetters({
      imageIsLoaded: "LOADED_STATE",
      credentials: "NEW_USER_CREDENTIALS",
    }),
    popupsInformation() {
      return this.$store.getters.STATE_POPUP;
    },
  },
  watch: {
    information: {
      deep: true,
      handler(newCount) {
        this.haveChanges =
          JSON.stringify(this.backupValue) === JSON.stringify(newCount);
      },
    },
    popupsInformation: {
      deep: true,
      handler(newCount) {
        if (newCount.save) {
          this.haveChanges = true;
          this.createExpert();
        }
        if (newCount.leave) {
          this.haveChanges = true;
          this.$router.push(newCount.to);
        }
      },
    },
  },
};
</script>

<style></style>
